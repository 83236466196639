/* eslint-disable react/prop-types */
// import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updateUtmSource } from "../Store/store";

export default function Redirect(props) {
  let actualPageIndex = useSelector(
    (state) => state.navigation.actualPageIndex
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source");

  if (utm_source != "" && utm_source != undefined){
    console.log("utm_source");
    dispatch(updateUtmSource(utm_source));
  }

  setTimeout(() => {
    if (props.pageIndex !== actualPageIndex - 1) {
      navigate("/page" + actualPageIndex);
    }
  }, 100);

  return "";
}
