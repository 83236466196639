import { AGE_DEPART_RETRAITE, INTEREST_RATE } from "../../../Config/config";

/**
 * @param {int} age Age of the lead
 * @returns {int}
 *
 * Function used to retrieve the period of saving for the lead.
 */

function getPeriodSaving(age) {
  return AGE_DEPART_RETRAITE - age;
}

/**
 * @param {int} versementMonthly Monthly versement on PER
 * @param {int} versementInitial Initial versement on PER
 * @param {int} age Age of the lead
 * @returns {Object["capitalTerme" : int, "capitalEvolution" : Array[float] "versmentEvolution" : Array[float], "interestEvolution" : Array[float]]}
 *
 * Function used to retrieve data about the CapitalPer, returns : Capital au terme, Evolution du capital PER, Evolution de la somme investis, Evolution des intérêts
 */

export function getCapitalPer(versementMonthly, versementInitial, age) {
  var versementAnnual = versementMonthly * 12;
  var capitalTerme = (versementAnnual + versementInitial) * (1 + INTEREST_RATE);
  var versementEvolution = [versementAnnual + versementInitial];
  var interestEvolution = [versementEvolution[0] * INTEREST_RATE];
  var capitalEvolution = [versementEvolution[0] + interestEvolution[0]];
  var periodSaving = getPeriodSaving(age);

  for (var i = 1; i < periodSaving; i++) {
    interestEvolution.push(
      interestEvolution[interestEvolution.length - 1] +
        (capitalTerme + versementAnnual) * INTEREST_RATE
    );
    capitalTerme = (capitalTerme + versementAnnual) * (1 + INTEREST_RATE);
    versementEvolution.push(
      versementEvolution[versementEvolution.length - 1] + versementAnnual
    );
    capitalEvolution.push(
      versementEvolution[versementEvolution.length - 1] +
        interestEvolution[interestEvolution.length - 1]
    );
  }

  return {
    capitalTerme: Math.round(capitalTerme),
    capitalEvolution: capitalEvolution,
    versmentEvolution: versementEvolution,
    interestEvolution: interestEvolution,
  };
}

/**
 *
 * @param {int} versementMonthly Monthly versement on PER
 * @param {int} versementInitial Initial versement on PER
 * @param {int} age Age of the lead
 * @param {float} INTEREST_RATE The interest Rate
 * @returns {int}
 *
 * Function used to retrieve the capital Per
 */

export function getCapitalPerAlone(versementMonthly, versementInitial, age) {
  var versementAnnual = versementMonthly * 12;
  var capitalTerme = (versementAnnual + versementInitial) * (1 + INTEREST_RATE);
  var periodSaving = getPeriodSaving(age);

  for (var i = 1; i < periodSaving; i++) {
    capitalTerme = (capitalTerme + versementAnnual) * (1 + INTEREST_RATE);
  }

  return Math.round(capitalTerme);
}

export function nbrPart(situation, kids) {
  if (
    situation === "Célibataire" ||
    situation === "Divorcé(e)" ||
    situation === "En concubinage"
  ) {
    if (kids === "Je n'ai pas d'enfants") {
      return 1;
    } else if (kids === "1 enfant") {
      return 1.5;
    } else if (kids === "2 enfants") {
      return 2;
    } else if (kids === "3 enfants") {
      return 3;
    } else if (kids === "4 enfants ou plus") {
      return 4;
    }
  } else if (situation === "Marié(e) / Pacsé(e)" || situation === "Veuf(ve)") {
    if (kids === "Je n'ai pas d'enfants") {
      return 2;
    } else if (kids === "1 enfant") {
      return 2.5;
    } else if (kids === "2 enfants") {
      return 3;
    } else if (kids === "3 enfants") {
      return 4;
    } else if (kids === "4 enfants ou plus") {
      return 5;
    }
  }
}

export function plafondDefisc(activity, income) {
  const annualIncome = income * 12;

  if (activity === "Indépendant(e) / Dirigeant(e)") {
    if (annualIncome < 41136) {
      return Math.round((0.1 * 41136) / 12);
    } else {
      return Math.round((0.1 * 41136 + 0.15 * (annualIncome - 41136)) / 12);
    }
  } else {
    if (annualIncome < 41136) {
      return Math.round((0.1 * 41136) / 12);
    } else {
      return Math.round((0.1 * annualIncome) / 12);
    }
  }
}

// Returns tranche marginale d'imposition
export function tmi(income, nbrPart) {
  income = (parseInt(income) * 12) / nbrPart;
  if (income <= 10084) {
    return 0;
  } else if (income >= 10085 && income <= 25710) {
    return 11;
  } else if (income >= 25711 && income <= 73516) {
    return 30;
  } else if (income >= 73517 && income <= 158122) {
    return 41;
  } else if (income > 158122) {
    return 45;
  }
}

// Economie d'impôts PER
export function ecoImpotsPer(activity, myTmi, income, versement) {
  return Math.round(
    Math.min(
      (plafondDefisc(activity, income) * 12 * myTmi) / 100,
      (versement * 12 * myTmi) / 100
    )
  );
}
