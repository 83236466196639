/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateNbrKids } from "../Store/store";

export default function Kids(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Combien d'enfants avez-vous à charge ?"
        subtitle="Pour affiner votre montant d'impôts"
        pageType="picklist"
        values={[
          "Je n'ai pas d'enfants",
          "1 enfant",
          "2 enfants",
          "3 enfants",
          "4 enfants ou plus",
        ]}
        reduxStateName="nbrKids"
        pageIndex={props.pageIndex}
        totalPages={props.totalPages}
        reduxAction={updateNbrKids}
        myNameDb="nbr_kids"
      />
    </div>
  );
}
