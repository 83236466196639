/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateProfessionalActivity } from "../Store/store";

export default function ProfessionalActivity(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Quelle est votre activité ?"
        subtitle="Pour identifier les meilleurs dispositifs"
        pageType="picklist"
        values={[
          "Salarié(e)",
          "Indépendant(e) / Dirigeant(e)",
          "Etudiant(e) / Apprenti(e)",
          "Au chômage / Sans activité",
        ]}
        reduxStateName="professionalActivity"
        pageIndex={props.pageIndex}
        reduxAction={updateProfessionalActivity}
        myNameDb="professional_activity"
        totalPages={props.totalPages}
      />
    </div>
  );
}
