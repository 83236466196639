import React from "react";
import { categories } from "../../Config/config.jsx";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import "./Navbar.scss";

let progress = (begin, end) => keyframes`
  0% { width: ${begin}%; }
  100% { width: ${end}%; }
  `;

let Foreground = styled.div`
  width: ${(props) => props.begin}%;
  animation: ${(props) =>
    progress(props.begin, props.end)} 0.9s ease 0.2s forwards};
  `;

// Function used to return the category within actualPageIndex
function findCategorie(pagePerCategoriesList, actualPageIndex) {
  for (let i = 0; i < pagePerCategoriesList.length; i++) {
    let lastIndex = pagePerCategoriesList[i].lastIndexOf(actualPageIndex);
    if (lastIndex > -1) {
      return [pagePerCategoriesList[i], i, lastIndex];
    }
  }

  return [null, null];
}

function buildProgressBar(category, categoryIndex, indexInCategory) {
  let categoriesBlock = [];
  let progressBars = [];
  for (let i = 0; i < categories.length; i++) {
    let myWidth = "";

    categoriesBlock.push(
      <div
        className={"category" + (categoryIndex === i ? " selected" : "")}
        key={i}
      >
        <p>{categories[i]}</p>
      </div>
    );

    if (i < categoryIndex) {
      myWidth = "100%";

      progressBars.push(
        <div key={i} className={"background " + categories[i]}>
          <div
            style={{ width: myWidth }}
            className={"foreground " + categories[i]}
          ></div>
        </div>
      );

      progressBars.push(
        <div key={i + " dot"} className="dot selected">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 6L9 17L4 12"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      );
    } else if (i === categoryIndex) {
      progressBars.push(
        <div key={i} className={"background " + categories[i]}>
          <Foreground
            begin={parseInt((indexInCategory * 100) / category.length)}
            end={parseInt(((indexInCategory + 1) * 100) / category.length)}
            style={{ width: myWidth }}
            className={"foreground " + categories[i]}
          />
        </div>
      );

      if (i + 1 !== categories.length) {
        progressBars.push(
          <div key={i + " dot"} className="dot unselected"></div>
        );
      }
    } else {
      myWidth = "0%";

      progressBars.push(
        <div key={i} className={"background " + categories[i]}>
          <div
            style={{ width: myWidth }}
            className={"foreground " + categories[i]}
          ></div>
        </div>
      );

      if (i + 1 !== categories.length) {
        progressBars.push(
          <div key={i + " dot"} className="dot unselected"></div>
        );
      }
    }
  }
  return { categoriesBlock, progressBars };
}

export default function Navbar() {
  let pagePerCategoriesList = useSelector(
    (state) => state.navigation.pagePerCategoriesList
  );
  let actualPageIndex = useSelector(
    (state) => state.navigation.actualPageIndex
  );
  let [category, categoryIndex, indexInCategory] = findCategorie(
    pagePerCategoriesList,
    actualPageIndex
  );
  const { categoriesBlock, progressBars } = buildProgressBar(
    category,
    categoryIndex,
    indexInCategory
  );

  return (
    <div className="nav-container no-display-phone">
      <nav>{categoriesBlock}</nav>

      <div className="progress-bar-container">{progressBars}</div>
    </div>
  );
}
