import { configureStore, createSlice } from "@reduxjs/toolkit";
import { backendUrl, defaultAllPages } from "../Config/config";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    categories: ["Famille", "Activité", "Finances", "Informations"],
    pagePerCategoriesList: [[1, 2, 3], [4, 5], [6], [7, 8, 9]],
    actualPageIndex: 1,
    tempPageIndex: 1,
    nextArrowState: "disable",
  },
  reducers: {
    nextPage: (state, action) => {
      if (
        state.actualPageIndex !== defaultAllPages.length &&
        state.nextArrowState === "activate"
      ) {
        state.nextArrowState = "disable";
        state.actualPageIndex++;

        const dbData = {
          session_id: action.payload.sessionId,
          timestamp: action.payload.timestamp,
          actual_page: state.actualPageIndex,
        };

        fetch(backendUrl + "simulations/per/update/index.php", {
          method: "POST",
          body: JSON.stringify(dbData),
          credentials: "include",
          crossDomain: true,
        });
      }
    },
    previousPage: (state, action) => {
      if (state.actualPageIndex !== 1) {
        state.nextArrowState = "activate";
        state.actualPageIndex--;

        const dbData = {
          session_id: action.payload.sessionId,
          timestamp: action.payload.timestamp,
          actual_page: state.actualPageIndex,
        };

        fetch(backendUrl + "simulations/per/update/index.php", {
          method: "POST",
          body: JSON.stringify(dbData),
          credentials: "include",
          crossDomain: true,
        });
      }
    },
    updateActualPageIndex: (state, action) => {
      state.actualPageIndex = action.payload;
    },
    updateNextArrowState: (state, action) => {
      state.nextArrowState = action.payload;
    },
    updateTempPageIndex: (state, action) => {
      state.tempPageIndex = action.payload;
    },
    putTempPageIndex: (state) => {
      state.actualPageIndex = state.tempPageIndex;
    },
  },
});

const userInputSlice = createSlice({
  name: "userInput",
  initialState: {
    familySituation: "",
    age: "",
    nbrKids: "",
    professionalActivity: "",
    income: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    county: "",
    optin: "false",
    dbData: [],
  },
  reducers: {
    prepareDataDB: (state, action) => {
      // First checking if the data to append is already in the list
      for (let i = 0; i < state.dbData.length; i++) {
        if (state.dbData[i][action.payload.nameDb] !== undefined) {
          state.dbData[i]["sessionId"] = action.payload.sessionId;
          state.dbData[i]["timestamp"] = action.payload.timestamp;
          state.dbData[i][action.payload.nameDb] = action.payload.content;

          return state;
        }
      }

      // If it's not in the list, we append the list
      let dbData = {
        session_id: action.payload.sessionId,
        timestamp: action.payload.timestamp,
      };

      dbData[action.payload.nameDb] = action.payload.content;

      state.dbData.push(dbData);
      return state;
    },
    sendDataDb: (state) => {
      const defaultLength = state.dbData.length;
      for (let i = 0; i < defaultLength; i++) {
        let myData = state.dbData.shift();
        fetch(backendUrl + "simulations/per/update/index.php", {
          method: "POST",
          body: JSON.stringify(myData),
          credentials: "include",
          crossDomain: true,
        });
      }
    },
    updateFamilySituation: (state, action) => {
      state.familySituation = action.payload;
    },
    updateAge: (state, action) => {
      state.age = action.payload;
    },
    updateNbrKids: (state, action) => {
      state.nbrKids = action.payload;
    },
    updateProfessionalActivity: (state, action) => {
      state.professionalActivity = action.payload;
    },
    updateIncome: (state, action) => {
      state.income = action.payload;
    },
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updatePhone: (state, action) => {
      state.phone = action.payload;
    },
    updateOptin: (state, action) => {
      state.optin = action.payload;
    },
    updateCounty: (state, action) => {
      state.county = action.payload;
    }
  },
});

const backendSlice = createSlice({
  name: "backend",
  initialState: {
    startedSimulation: "false",
    phoneChecked: "false",
    sessionId: "",
    timestamp: "",
    utmSource: "",
  },
  reducers: {
    updateStartedSimulation: (state, action) => {
      state.startedSimulation = action.payload;
    },
    updatePhoneChecked: (state, action) => {
      state.phoneChecked = action.payload;
    },
    updateSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    updateTimestamp: (state, action) => {
      state.timestamp = action.payload;
    },
    updateUtmSource: (state, action) => {
      state.utmSource = action.payload;
    }
  },
});

export const {
  updateFamilySituation,
  updateAge,
  updateNbrKids,
  updateProfessionalActivity,
  updateIncome,
  updateFirstName,
  updateLastName,
  updateEmail,
  updatePhone,
  updateOptin,
  updateCounty,
  prepareDataDB,
  sendDataDb,
} = userInputSlice.actions;

export const {
  nextPage,
  previousPage,
  updateNextArrowState,
  putTempPageIndex,
  updateTempPageIndex,
  updateActualPageIndex,
} = navigationSlice.actions;

export const {
  updateStartedSimulation,
  updatePhoneChecked,
  updateSessionId,
  updateTimestamp,
  updateUtmSource,
} = backendSlice.actions;

export const store = configureStore({
  reducer: {
    navigation: navigationSlice.reducer,
    userInput: userInputSlice.reducer,
    backend: backendSlice.reducer,
  },
});
