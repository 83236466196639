/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateFamilySituation } from "../Store/store";

export default function FamilySituation(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Quelle est votre situation familiale ?"
        subtitle="Pour calculer le montant d'impôts"
        pageType="picklist"
        values={[
          "Célibataire",
          "Marié(e) / Pacsé(e)",
          "Divorcé(e)",
          "En concubinage",
          "Veuf(ve)",
        ]}
        reduxStateName="familySituation"
        pageIndex={props.pageIndex}
        reduxAction={updateFamilySituation}
        myNameDb="family_situation"
        totalPages={props.totalPages}
      />
    </div>
  );
}
