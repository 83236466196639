/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { regexRemoveSpaces } from "../Config/utils";
import { updateCounty } from "../Store/store";

function checkData(value) {
  const numerousValue = parseInt(regexRemoveSpaces(value));

  return numerousValue > 0 && numerousValue < 1000;
}

export default function Localisation(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Dans quel département travaillez-vous ?"
        subtitle="Pour identifier les meilleurs dispositifs de votre région"
        pageType="input"
        type="numerous"
        placeholder="Ex: 75"
        reduxAction={updateCounty}
        reduxStateName="county"
        checkData={checkData}
        dataType="integer"
        myNameDb="county"
        pageIndex={props.pageIndex}
        totalPages={props.totalPages}
      />
    </div>
  );
}
