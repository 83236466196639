import { React } from "react";
import FamilySituation from "../Pages/FamilySituation";
import Age from "../Pages/Age";
import Kids from "../Pages/Kids";
import ProfessionalActivity from "../Pages/ProfessionalActivity";
import Income from "../Pages/Income";
import LastName from "../Pages/LastName";
import Email from "../Pages/Email";
import Phone from "../Pages/Phone";
import Localisation from "../Pages/Localisation";
import Results from "../Pages/Results";


export const simulateur_name = "PER";
export const categories = ["Famille", "Activité", "Finances", "Informations"];
// eslint-disable-next-line react/jsx-key
export const defaultAllPages = [<FamilySituation />, <Age />, <Kids />, <ProfessionalActivity />, <Localisation /> , <Income />, <LastName />, <Email />, <Phone />, <Results />];
export const ACTUAL_YEAR = 2022;
export const backendUrl = "https://backend.lexem.io/";
export const AGE_DEPART_RETRAITE = 65;
export const INTEREST_RATE = 0.04;